import { EnterOutlined, EnvironmentOutlined, SwapOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Input, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import debounce from 'lodash/debounce';
import { Moment } from 'moment-jalaali';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAntdValidation, useGlobalization, useHttpCall } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { CultureName } from '../../../../../features/localization/cultures';
import { disableOtherDatesAntdDatePicker, getNowISO } from '../../../../../utils/helpers';
import { ImprovedAutoComplete, PDatePicker2 } from '../../../../shared';
import { GetAirportResponse, GetAirportResponseItem } from '../../../apiTypes';
import { getAirportHttp } from '../../../http/flight-http';
import { FareQuoteFormValues } from '../../../types';
import classes from './FareQuoteOriginDestination.module.scss';

type Props = {
  inline?: boolean;
  onChangeReturn: (hasReturn: boolean) => void;
  hasReturn: boolean;
  needTicketingDate: boolean;
  formInstance: FormInstance<FareQuoteFormValues>;
  departureDate: Moment | undefined;
};
const FareQuoteOriginDestination: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { culture } = useGlobalization();

  const [fromAirports, setFromAirports] = useState<GetAirportResponse>([]);
  const [toAirports, setToAirports] = useState<GetAirportResponse>([]);
  const [departureDate, setDepartureDate] = useState<Moment>();
  // const [returnDate, setReturnDate] = useState<Moment>();

  const departureDateHasSetRef = useRef(false);

  const getAirportApi = useHttpCall(getAirportHttp);

  const { labelWithRules } = useAntdValidation(props.formInstance);

  const airportSearchSegment = useRef<'from' | 'to'>('from');

  const destinationInputRef = useRef<Input>(null);

  const debouncedGetAirports = debounce(async (value: string, to: boolean) => {
    airportSearchSegment.current = to ? 'to' : 'from';
    const response = await getAirportApi.call<GetAirportResponse>({ value: value });
    if (response && response.success && response.result) {
      if (to) {
        setToAirports(response.result!);
      } else {
        setFromAirports(response.result!);
      }
    } else {
      if (to) {
        setToAirports([]);
      } else {
        setFromAirports([]);
      }
    }
  }, 300);

  const handleAirportSearched = async (value: string, to: boolean) => {
    if (value && value.length > 2) {
      await debouncedGetAirports(value, to);
    } else {
      if (to) {
        setToAirports([]);
      } else {
        setFromAirports([]);
      }
    }
  };

  const handleSwapOriginDestination = () => {
    const validateSection = (obj: GetAirportResponseItem) => {
      return obj?.Code;
    };

    const values = props.formInstance.getFieldsValue();

    if (validateSection(values.originCode) && validateSection(values.destinationCode)) {
      props.formInstance.setFieldsValue({
        originCode: values.destinationCode,
        destinationCode: values.originCode,
      });
    }
  };

  useEffect(() => {
    if (!departureDateHasSetRef.current && props.departureDate) {
      setDepartureDate(props.departureDate);
      departureDateHasSetRef.current = true;
    }
  }, [props.departureDate]);

  return (
    <Row gutter={[10, 0]}>
      <Col xs={24} md={props.inline ? (props.hasReturn ? 12 : 14) : 24} xl={props.inline ? (props.hasReturn ? 12 : 14) : 24}>
        <Row gutter={[10, 0]} wrap={false}>
          <Col flex={'auto'}>
            <Form.Item className="mb-4" name="originCode" {...labelWithRules({ label: t(Translations.Common.From), rules: [{ type: 'Required' }] })}>
              <ImprovedAutoComplete
                asyncPending={airportSearchSegment.current === 'from' && getAirportApi.pending}
                //additionVisibleCondition={flightSearchTempState.dropdownVisibility}
                textPropertyName="Name"
                renderTooltip={(option) => (
                  <>
                    {option.ParentName}
                    <br />
                    {option.Name}
                  </>
                )}
                options={fromAirports}
                onInputChanged={(value) => handleAirportSearched(value, false)}
                onChange={(item) => {
                  if (item) {
                    destinationInputRef.current?.focus();
                  }
                }}
                renderOption={(option) => (
                  <Row className={`no-wrap-flex ${option.Type === 2 ? 'pl-5' : ''}`}>
                    <Col flex="20px">{option.Type === 1 ? <EnvironmentOutlined className="align-middle" /> : <EnterOutlined className="align-middle" />}</Col>
                    <Col flex="auto">
                      <div>{option.Name}</div>
                      <small>{option.ParentName}</small>
                    </Col>
                  </Row>
                )}
              />
            </Form.Item>
          </Col>
          <Col flex={'40px'}>
            <button className={'origin-destination-toggle-btn align-with-formItems'} type={'button'} onClick={handleSwapOriginDestination}>
              <SwapOutlined />
            </button>
          </Col>
          <Col flex={'auto'}>
            <Form.Item className="mb-4" name="destinationCode" {...labelWithRules({ label: t(Translations.Common.To2), rules: [{ type: 'Required' }] })}>
              <ImprovedAutoComplete
                inputRef={destinationInputRef}
                asyncPending={airportSearchSegment.current === 'to' && getAirportApi.pending}
                textPropertyName="Name"
                renderTooltip={(option) => (
                  <>
                    {option.ParentName}
                    <br />
                    {option.Name}
                  </>
                )}
                options={toAirports}
                onInputChanged={(value) => handleAirportSearched(value, true)}
                renderOption={(option) => (
                  <Row className={`no-wrap-flex ${option.Type === 2 ? 'pl-5' : ''}`}>
                    <Col flex="20px">{option.Type === 1 ? <EnvironmentOutlined className="align-middle" /> : <EnterOutlined className="align-middle" />}</Col>
                    <Col flex="auto">
                      <div>{option.Name}</div>
                      <small>{option.ParentName}</small>
                    </Col>
                  </Row>
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col xs={24} md={props.inline ? (props.hasReturn ? 4 : 6) : 24} xl={props.inline ? (props.hasReturn ? 5 : 6) : 24}>
        <Form.Item className="mb-4" name="departureDate" {...labelWithRules({ label: t(Translations.Flight.DepartureDate), rules: [{ type: 'Required' }] })}>
          {culture.Name === CultureName.FaIr ? (
            <PDatePicker2 initialCalendar="persian" minDate={getNowISO()} />
          ) : (
            <DatePicker
              inputReadOnly
              dropdownClassName="disabled-year-arrow"
              className={'w-100'}
              // disabledDate={(current) => disableOtherDatesAntdDatePicker(current, undefined, returnDate, { disableYesterdayAndBefore: true })}
              onChange={(value) => setDepartureDate(value ?? undefined)}
            />
          )}
        </Form.Item>
      </Col>
      {props.hasReturn && (
        <Col xs={24} md={props.inline ? 4 : 24} xl={props.inline ? 4 : 24}>
          <Form.Item
            key={departureDate?.toISOString()}
            className="mb-4"
            name="returnDate"
            {...labelWithRules({ label: t(Translations.Flight.ReturnDate), rules: [{ type: 'Required' }] })}>
            {culture.Name === CultureName.FaIr ? (
              <PDatePicker2 initialCalendar="persian" minDate={getNowISO()} />
            ) : (
              <DatePicker
                inputReadOnly
                dropdownClassName="disabled-year-arrow"
                className={'w-100'}
                defaultPickerValue={departureDate}
                disabledDate={(current) => disableOtherDatesAntdDatePicker(current, departureDate, undefined)}
                //onChange={(value) => setReturnDate(value ?? undefined)}
              />
            )}
          </Form.Item>
        </Col>
      )}
      {props.needTicketingDate && (
        <Col xs={24} md={props.inline ? 4 : 24} xl={props.inline ? 4 : 24}>
          <Form.Item
            key={departureDate?.toISOString()}
            className="mb-4"
            name="ticketingDate"
            {...labelWithRules({ label: t(Translations.Flight.TicketingDate), rules: [{ type: 'Required' }] })}>
            {culture.Name === CultureName.FaIr ? (
              <PDatePicker2 initialCalendar="persian" minDate={getNowISO()} />
            ) : (
              <DatePicker
                inputReadOnly
                dropdownClassName="disabled-year-arrow"
                className={'w-100'}
                defaultPickerValue={departureDate}
                disabledDate={(current) => current && current > departureDate!.startOf('day')}
              />
            )}
          </Form.Item>
        </Col>
      )}
      <Col xs={24} md={4} xl={props.inline ? (props.hasReturn ? 3 : 4) : 24}>
        <button
          className={`align-with-formItems-md align-with-formItems-lg align-with-formItems-xl w-100 ${classes.addReturnBtn}`}
          type={'button'}
          onClick={() => props.onChangeReturn(!props.hasReturn)}>
          {props.hasReturn ? 'remove return' : 'add return'}
        </button>
      </Col>
    </Row>
  );
});

export default FareQuoteOriginDestination;
