import { Env } from '../../utils/env';

type TenantHostSection =
  | 'Flight'
  | 'Hotel'
  | 'Package'
  | 'Ethnic'
  | 'Ticketing'
  | 'HotelDomestic'
  | 'ChargeDeposit'
  | 'FlightDomestic'
  | 'Subscription'
  | 'FlightVoidAndCancel'
  | 'FlightPNRResultSupplier'
  | 'FlightTicketingShowSupplier'
  | 'FlightFareView'
  | 'FlightFlexCalendar';
type TenantHostConfigType = {
  DisabledSections: Readonly<TenantHostSection[]>;
};
export let TenantHostConfig: TenantHostConfigType;

export const AyanAirConfig: TenantHostConfigType = {
  DisabledSections: ['FlightDomestic', 'Ethnic', 'Package', 'Subscription', 'FlightVoidAndCancel', 'FlightPNRResultSupplier', 'FlightTicketingShowSupplier'],
} as const;

export const ProTravelingConfig: TenantHostConfigType = {
  DisabledSections: [
    'ChargeDeposit',
    'FlightDomestic',
    'Ethnic',
    'HotelDomestic',
    'Package',
    'Subscription',
    'FlightVoidAndCancel',
    'FlightPNRResultSupplier',
    'FlightTicketingShowSupplier',
  ],
} as const;

export const iToursSwedenConfig: TenantHostConfigType = {
  DisabledSections: ['ChargeDeposit', 'FlightDomestic', 'Ethnic', 'HotelDomestic', 'Package', 'Subscription', 'FlightFareView', 'FlightFlexCalendar'],
} as const;

export const iToursIraqConfig: TenantHostConfigType = {
  DisabledSections: ['ChargeDeposit', 'FlightDomestic', 'Ethnic', 'HotelDomestic', 'Package', 'Subscription', 'FlightFareView', 'FlightFlexCalendar'],
} as const;

export const iToursConfig: TenantHostConfigType = {
  DisabledSections: [],
} as const;

export function initTenantHostConfig() {
  switch (Env.TenantHost) {
    case 'iTours':
      TenantHostConfig = iToursConfig;
      break;
    case 'AyanAir':
    case 'BarreTravel':
      TenantHostConfig = AyanAirConfig;
      break;
    case 'ProTraveling':
      TenantHostConfig = ProTravelingConfig;
      break;
    case 'iToursSweden':
      TenantHostConfig = iToursSwedenConfig;
      break;
    case 'iToursIraq':
      TenantHostConfig = iToursIraqConfig;
      break;
  }
}

export function isSectionEnabled(section: TenantHostSection) {
  return !TenantHostConfig.DisabledSections.includes(section);
}
